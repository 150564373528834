/* import Alert from '../components/alert' */
import Footer from '../components/footer'
import Meta from '../components/meta'
/* import { createClient } from 'contentful' */
import Head from 'next/head'
import Header from '../components/header'
import Image from 'next/image'
import Link from 'next/link'
/* import Header from '../components/header' */
import { useEffect } from 'react';
import Layout from '../components/layout'

export default function Homepage({ preview, allPosts, allExemples, siteEnv }) {
 

  return (
    <>
    <Layout preview={preview} siteEnv={siteEnv}>
        <Head>
          <meta charSet="utf-8"/>
          <title>Progressive Web Apps (PWA)</title>
          <meta name="theme-color" content="#f27b00" />
          <meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"/>
          <meta name="format-detection" content="telephone=no"/>
          <meta name="apple-mobile-web-app-capable" content="yes"/>
          <meta name="apple-mobile-web-app-status-bar-style" content="#ff7300"/>
          <meta name="description" content="Progressive-web-apps.fr est un site français spécialisé sur les applications web progressives ou PWA. 
          Nous proposons des articles, comparatifs, tutoriels par spécifiques à ce domaine."/>
          <meta name="robots" content="index, follow"/>
          <meta property="image" content="progressive-web-apps.fr/fichiers/page/progressive-web-apps-fr-1.png"/>
          <meta property="og:title" content="Progressive Web Apps.fr - Site francophone dédié aux actus des PWA"/>
          <meta property="og:description" content="Progressive-web-apps.fr est un site français spécialisé sur les applications web progressives ou PWA. 
          Nous proposons des articles, comparatifs, tutoriels par spécifiques à ce domaine."/>
          <meta property="og:image" content="https://progressive-web-apps.fr/fichiers/page/progressive-web-apps-fr_1200x630.png"/>
          <meta property="og:locale" content="fr"/>
          <meta property="og:site_name" content="progressive-web-apps"/>
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://progressive-web-apps.fr/"/>
          <meta property="article:tag" content=""/>
          <meta property="article:section" content="Technologie"/>
          <meta property="article:published_time" content=""/>
          <meta property="article:modified_time" content=""/>
          <meta property="article:author" content="Jonathan Rossi"/>
          <meta property="fb:app_id" content="1795695950672896"/>
          <meta name="twitter:card" content=""/>
          <meta name="twitter:site" content=""/>
          <meta name="twitter:url" content="https://progressive-web-apps.fr/"/>
          <meta name="twitter:title" content="Progressive Web Apps.fr - Site francophone dédié aux actus des PWA"/>
          <meta name="twitter:description" content="Progressive-web-apps.fr est un site français spécialisé sur les applications web progressives ou PWA. 
          Nous proposons des articles, comparatifs, tutoriels par spécifiques à ce domaine."/>
          <meta name="twitter:image" content="https://progressive-web-apps.fr/fichiers/page/progressive-web-apps-fr-2.png"/>
          <link href="https://progressive-web-apps.fr/" rel="canonical"/>
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        </Head>
        <div>
          <Header />
          
          <section className="py-28 text-white text-center bg-fixed relative">
            <Image className="z-10 absolute inset-0 object-cover" layout='fill' src="/imgs/img-bg-sombre.jpg" />

            <div className="container mx-auto px-5 grid grid-cols-1 gap-4 relative z-20 bg-transparent lg:grid-cols-12">
              <div className="col-span-1 lg:col-start-3 lg:col-span-8">
                <h1 className="text-5xl leading-10 mb-14 font-light"><span className="text-color-primary">Progressive Web Apps</span>.fr</h1>
                <h2 className="text-5xl leading-10 mb-3 font-light">Actualités & informations sur les PWA, le futur des applications mobiles</h2>
                <p className="text-base mb-4 font-light">
                  Site francophone spécialisé dans le domaine des Progressive Web Apps
                  <br/>Tout savoir sur les PWA, le futur du web !
                </p>
              </div>
            </div>
          </section>

          <section>
            <div className="container mx-auto px-5 py-8 grid gap-4 grid-cols-1 relative z-20 bg-transparent lg:grid-cols-12 py-12">
              <div className="col-span-1 lg:col-span-8 lg:col-start-3 text-center">
                <h2 className="text-2xl leading-10 mb-2">Actualités</h2>
                <p className="text-small mb-4 text-gray-500">
                Les dernières nouvelles sur les Progressives Web Apps !
                </p>
                <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-2">
                  {/* Cards */}
                  {allPosts?.map( (post, index) => (
                    <div className="card-article relative overflow-hidden rounded-lg shadow-md hover:transform hover:translate-y-4" key={index}>
                      <Link href={post?.url}>
                        <a>
                          <div className="relative card-cus">
                            <Image className="w-full absolute inset-0 object-cover" layout="fill" src={post?.image?.url ? post?.image?.url+"?fm=webp&q=60&h=250" : "/imgs/img-bg-sombre.jpg" } loading='eager'/>
                            <div className="card-hover-element absolute top-0 left-0 w-full h-full bg-color-primary bg-opacity-50">
                              <span className="absolute py-1 px-2 text-color-primary bg-white">LIRE</span>
                            </div>
                          </div>

                          <div className="h-24 py-2 px-4">
                            <p className="text-xs text-gray-500 mb-2">Le {post?.dateCreation && new Date(post?.dateCreation).toLocaleDateString('fr-FR', {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            })}</p>
                            <h2 className="text-sm" dangerouslySetInnerHTML={{ __html: post?.h1}}></h2>
                          </div>
                        </a>
                      </Link>
                    </div>
                  ))}
                  
                  
                </div>
                
                <button className="btn-primary mt-4">
                    <Link href="/actualites">
                        <a>
                            Plus d&apos;articles
                        </a>
                    </Link>
                  
                </button>
              </div>
            </div>
          </section>

          <section>
            <div className="container mx-auto px-5 py-8 grid gap-4 grid-cols-1 relative z-20 bg-transparent lg:grid-cols-12 py-12">
              <div className="col-span-1 lg:col-span-8 lg:col-start-3 text-center">
                <h2 className="text-2xl leading-10 mb-2">Cas d&apos;utilisation</h2>
                <p className="text-small mb-4 text-gray-500">
                  Exemples d&apos;utilisation des progressive web apps
                </p>
                <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-2">
                  {allExemples?.map( (post, index) => (
                    <div className="card-article relative overflow-hidden rounded-lg shadow-md hover:transform hover:translate-y-4" key={index}>
                      <Link href={post?.url}>
                        <a>
                          <div className="card-cus relative">
                          <Image className="w-full absolute inset-0 object-cover" layout="fill" 
                            src={ post?.image?.url ? post?.image?.url+"?fm=webp&q=60&h=250" : "/imgs/img-bg-sombre.jpg" }  loading='eager'/>
                            <div className="card-hover-element absolute top-0 left-0 w-full h-full bg-color-primary bg-opacity-50">
                              <span className="absolute py-1 px-2 text-color-primary bg-white">LIRE</span>
                            </div>
                          </div>

                          <div className="h-24 py-2 px-4">
                            <p className="text-xs text-gray-500 mb-2">Le {post?.dateCreation && new Date(post?.dateCreation).toLocaleDateString('fr-FR', {
                              day: 'numeric',
                              month: 'long',
                              year: 'numeric',
                            })}</p>
                            <h2 className="text-sm" dangerouslySetInnerHTML={{ __html: post?.h1}}></h2>
                          </div>
                        </a>
                      </Link>
                    </div>
                  ))}
                
                </div>
              </div>
            </div>
          </section>

          {/* <section className="bg-color-grey">
            <div className="container mx-auto px-5 py-8 grid gap-4 grid-cols-1 relative z-20 bg-transparent lg:grid-cols-12 py-12">
              <div className="col-span-1 lg:col-span-8 lg:col-start-3 text-center">
                <h2 className="text-2xl leading-10 mb-2">Derniers messages sur le forum</h2>
                <p className="text-small mb-4 text-gray-500">
                  <Link href="/forum">
                    <a className="text-color-primary">
                      Visitez notre forum
                    </a>
                  </Link>
                      et  
                    <Link href="/connexion">
                    <a className="text-color-primary">
                    connectez-vous 
                    </a>
                  </Link>
                      ou  
                    <Link href="/inscription">
                    <a className="text-color-primary">
                      inscrivez-vous 
                    </a>
                  </Link> pour participer aux échanges !
                </p>
                
                <div className="grid gap-4 grid-cols-1 lg:grid-cols-2 mb-2">

                  <div className="card-article bg-white py-2 px-8 relative overflow-hidden rounded-lg shadow-md hover:transform hover:translate-y-4">
                    
                    <div className="flex align-items justify-start mb-2">
                      <div className="w-12 h-12 bg-yellow-600 rounded-full text-center p-3 mr-5 text-white">
                        P
                      </div>
                      <div className="text-left">
                        <h3>PierreClaret</h3>
                        <p>Le 03 Juin 2021</p>
                      </div>
                    </div>

                    <div className="h-18 py-2 text-left">
                      <h2 className="text-sm text-gray-500">Achetez des figurines de haute qualité dans notre vaste collection de figurines. Boutique officielle de figurines d&apos;anime / manga, etam ch...  <Link href="/">
                        <a className="text-color-primary">Lire la suite</a>
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="card-article bg-white py-2 px-8 relative overflow-hidden rounded-lg shadow-md hover:transform hover:translate-y-4">
                    
                    <div className="flex align-items justify-start mb-2">
                      <div className="w-12 h-12 bg-yellow-600 rounded-full text-center p-3 mr-5 text-white">
                        P
                      </div>
                      <div className="text-left">
                        <h3>PierreClaret</h3>
                        <p>Le 03 Juin 2021</p>
                      </div>
                    </div>

                    <div className="h-18 py-2 text-left">
                      <h2 className="text-sm text-gray-500">Achetez des figurines de haute qualité dans notre vaste collection de figurines. Boutique officielle de figurines d&apos;anime / manga, etam ch...  <Link href="/">
                        <a className="text-color-primary">Lire la suite</a>
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="card-article bg-white py-2 px-8 relative overflow-hidden rounded-lg shadow-md hover:transform hover:translate-y-4">
                    
                    <div className="flex align-items justify-start mb-2">
                      <div className="w-12 h-12 bg-yellow-600 rounded-full text-center p-3 mr-5 text-white">
                        P
                      </div>
                      <div className="text-left">
                        <h3>PierreClaret</h3>
                        <p>Le 03 Juin 2021</p>
                      </div>
                    </div>

                    <div className="h-18 py-2 text-left">
                      <h2 className="text-sm text-gray-500">Achetez des figurines de haute qualité dans notre vaste collection de figurines. Boutique officielle de figurines d&apos;anime / manga, etam ch...  <Link href="/">
                        <a className="text-color-primary">Lire la suite</a>
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="card-article bg-white py-2 px-8 relative overflow-hidden rounded-lg shadow-md hover:transform hover:translate-y-4">
                    
                    <div className="flex align-items justify-start mb-2">
                      <div className="w-12 h-12 bg-yellow-600 rounded-full text-center p-3 mr-5 text-white">
                        P
                      </div>
                      <div className="text-left">
                        <h3>PierreClaret</h3>
                        <p>Le 03 Juin 2021</p>
                      </div>
                    </div>

                    <div className="h-18 py-2 text-left">
                      <h2 className="text-sm text-gray-500">Achetez des figurines de haute qualité dans notre vaste collection de figurines. Boutique officielle de figurines d&apos;anime / manga, etam ch...  <Link href="/">
                        <a className="text-color-primary">Lire la suite</a>
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="card-article bg-white py-2 px-8 relative overflow-hidden rounded-lg shadow-md hover:transform hover:translate-y-4">
                    
                    <div className="flex align-items justify-start mb-2">
                      <div className="w-12 h-12 bg-yellow-600 rounded-full text-center p-3 mr-5 text-white">
                        P
                      </div>
                      <div className="text-left">
                        <h3>PierreClaret</h3>
                        <p>Le 03 Juin 2021</p>
                      </div>
                    </div>

                    <div className="h-18 py-2 text-left">
                      <h2 className="text-sm text-gray-500">Achetez des figurines de haute qualité dans notre vaste collection de figurines. Boutique officielle de figurines d&apos;anime / manga, etam ch...  <Link href="/">
                        <a className="text-color-primary">Lire la suite</a>
                        </Link>
                      </h2>
                    </div>
                  </div>
                  <div className="card-article bg-white py-2 px-8 relative overflow-hidden rounded-lg shadow-md hover:transform hover:translate-y-4">
                    
                    <div className="flex align-items justify-start mb-2">
                      <div className="w-12 h-12 bg-yellow-600 rounded-full text-center text-white p-3 mr-5">
                        P
                      </div>
                      <div className="text-left">
                        <h3>PierreClaret</h3>
                        <p>Le 03 Juin 2021</p>
                      </div>
                    </div>

                    <div className="h-18 py-2 text-left">
                      <h2 className="text-sm text-gray-500">Achetez des figurines de haute qualité dans notre vaste collection de figurines. Boutique officielle de figurines d&apos;anime / manga, etam ch...  <Link href="/">
                        <a className="text-color-primary">Lire la suite</a>
                        </Link>
                      </h2>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </section>
         */}
        </div>
      </Layout>
    </>
  )
}