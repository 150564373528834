
export default function EcranSvg() {
    return (
        
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" id="ecransvg" 
            viewBox="-498.7 -763.6 2100 1684.8" enableBackground="new -498.7 -763.6 2100 1684.8" xmlSpace="preserve">
        <g id="base_Imac">
            <line fill="none" x1="-480.3" y1="486.8" x2="1582.9" y2="486.8"/>
            <path id="f1" stroke="#ff7300" fill="none" d="M790.6,771.6c11.2,97.1,19.6,100.7,22.4,101.9c7.9,3.4,29.8,6.8,51,10.1c15.1,2.3,33.7,5.2,37.3,7v3.2
                c-9.5,2.4-83.1,3.7-218.8,3.7c-32.1,0-62.6-0.1-89.5-0.1c-16.9,0-31.8-0.1-43.7-0.1c-11.9,0-26.8,0-43.7,0.1
                c-26.9,0.1-57.3,0.1-89.5,0.1c-135.8,0-209.4-1.2-218.8-3.7v-3.2c3.6-1.8,22.2-4.6,37.3-7c21.2-3.3,43.1-6.6,51-10.1
                c2.8-1.2,11.2-4.9,22.4-101.9c3.7-31.8,6.6-65.2,8.2-90.5H312c-1.7,25.2-4.6,58.4-8.2,90c-10.4,90.2-18.5,98.1-20.1,98.8
                c-7.4,3.2-30,6.7-50,9.8c-35.7,5.5-40.6,6.7-40.6,10.1v5.1c0,1.3,0,2.3,6.7,3.2c0,1.7,0,4.2,0,6c0,3.8,114,4.5,217.2,4.5
                c52.2,0,101.6-0.2,132.1-0.2c30.5,0,80,0.2,132.1,0.2c103.2,0,217.2-0.7,217.2-4.5c0-1.7,0-4.3,0-6c6.7-0.9,6.7-1.9,6.7-3.2v-5.1
                c0-3.4-4.9-4.6-40.6-10.1c-20-3.1-42.6-6.5-50-9.8c-1.5-0.7-9.6-8.5-20.1-98.8c-3.6-31.6-6.5-64.8-8.2-90h-3.9
                C784,706.5,786.9,739.9,790.6,771.6z M895.5,902.1c-4.5,0.8-20.2,2-71.9,2.8c-34.6,0.5-81.2,0.7-142.4,0.7
                c-31.6,0-61.7-0.1-88.3-0.1c-16.9,0-31.8-0.1-43.9-0.1c-12,0-27,0-43.9,0.1c-26.5,0.1-56.6,0.1-88.3,0.1
                c-61.2,0-107.8-0.2-142.4-0.7c-51.6-0.7-67.4-1.9-71.9-2.8v-3.7c9.3,0.9,27.6,1.6,63.5,2.1c35.7,0.5,84.6,0.8,149.7,0.8
                c32.2,0,62.6-0.1,89.5-0.1c16.9,0,31.8-0.1,43.7-0.1c11.9,0,26.8,0,43.7,0.1c26.9,0.1,57.3,0.1,89.5,0.1c65.1,0,114-0.3,149.7-0.8
                c35.9-0.6,54.2-1.2,63.5-2.1V902.1z"/>
        </g>
        <g id="contour">
            <path id="f2" fill="none" stroke="#ff7300" strokeWidth="7" strokeMiterlimit="10" d="M1582.9,606.1c0,39.8-31.6,72.1-70.6,72.1H-406.6
                c-39,0-70.6-32.3-70.6-72.1v-1286c0-39.8,31.6-72.1,70.6-72.1h1918.9c39,0,70.6,32.3,70.6,72.1V606.1z"/>
        </g>
        <g id="écran">
            <path id="f3" fill="none" stroke="#ff7300" strokeWidth="7" strokeMiterlimit="10" d="M1498.1,397.8c0,5.5-4.5,10-10,10H-385.5
                c-5.5,0-10-4.5-10-10v-1057c0-5.5,4.5-10,10-10h1873.6c5.5,0,10,4.5,10,10V397.8z"/>
        </g>
        <g id="camera">
            <circle fill="none" stroke="#ff7300" strokeWidth="4" strokeMiterlimit="10" cx="551.3" cy="-708.5" r="6.8"/>
        </g>
        <g id="logo">
            <path id="f4" fill="none" stroke="#ff7300" strokeWidth="3" strokeMiterlimit="10" d="M92.3-472.3c0,1.9-1.5,3.4-3.4,3.4h-373.8
                c-1.9,0-3.4-1.5-3.4-3.4v-100.1c0-1.9,1.5-3.4,3.4-3.4H88.9c1.9,0,3.4,1.5,3.4,3.4V-472.3z"/>
        </g>
        <g id="slider">
            <path id="f5" fill="none" stroke="#ff7300" strokeWidth="3" strokeMiterlimit="10" d="M1358.5,77.8c0,2.8-2.2,5-5,5H-283.4
                c-2.8,0-5-2.2-5-5v-519.7c0-2.8,2.2-5,5-5h1636.9c2.8,0,5,2.2,5,5V77.8z"/>
        </g>
        <g id="bloc_1">
            <path id="f6" fill="none" stroke="#ff7300" strokeWidth="3" strokeMiterlimit="10" d="M253.1,351c0,3.5-2.2,6.3-5,6.3h-531.5
                c-2.8,0-5-2.8-5-6.3V118.9c0-3.5,2.2-6.3,5-6.3h531.5c2.8,0,5,2.8,5,6.3V351z"/>
        </g>
        <g id="bloc_2">
            <path id="f7" fill="none" stroke="#ff7300" strokeWidth="3" strokeMiterlimit="10" d="M805.8,351c0,3.5-2.2,6.3-5,6.3H269.4
                c-2.8,0-5-2.8-5-6.3V118.9c0-3.5,2.2-6.3,5-6.3h531.5c2.8,0,5,2.8,5,6.3V351z"/>
        </g>
        <g id="bloc_3">
            <path id="f8" fill="none" stroke="#ff7300" strokeWidth="3" strokeMiterlimit="10" d="M1358.5,351c0,3.5-2.2,6.3-5,6.3H822.1
                c-2.8,0-5-2.8-5-6.3V118.9c0-3.5,2.2-6.3,5-6.3h531.5c2.8,0,5,2.8,5,6.3V351z"/>
        </g>
        </svg>
    )
  }
  