
export default function OrdiSvg() {
    return (
        
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" id="ordisvg"
            viewBox="957.8 -104 1970.1 1131.9" enableBackground="new 957.8 -104 1970.1 1131.9" xmlSpace="preserve">
        <g id="bas_ordi">
            <path id="o1" fill="none" stroke="#ff7300" d="M2928,985.3v-4.5c0-2.2-1.8-4-4-4h-823.9c-0.9,0-1.8,0.3-2.5,0.9h-321.1c-0.7-0.5-1.6-0.9-2.5-0.9H961.8
                c-2.2,0-4,1.8-4,4v3.5c0,1.6,0.7,3.2,2,4.6l0,0c1.4,1.5,5.8,6,36.3,12.9c19,4.3,44.2,8.6,72.7,12.4c64.8,8.7,135.8,13.7,194.7,13.7
                h1315.1c121.5,0,205.4-8.6,254.4-15.9c48.4-7.1,86.6-16.6,93.1-23c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0.1-0.1,0.1-0.2,0.2-0.2
                c0,0,0.1-0.1,0.1-0.1C2927.5,987.5,2928,986.4,2928,985.3z M2924,985.3c0,0.2-0.2,0.5-0.5,0.8h-819.4v-4.5v-0.9H2924
                C2924,980.8,2924,984.3,2924,985.3z M2100.1,981.6c0,1.6,0,2.4,0,4.5c-1.9,2-5.1,3.6-11,3.6c-4.8,0-60,0-109.1,0c0,0-41.6,0-86.1,0
                c-49,0-101.7,0-106.5,0c-5.9,0-11.6-1.6-13.5-3.6v0c0-2.3,0-3.3,0-4.5H2100.1z M961.8,980.8H1770v0.9v4.5H962.7
                c-0.6-0.6-0.9-1.2-0.9-1.8C961.8,983.1,961.8,980.8,961.8,980.8z M2578.7,1023.9c0,0-1267.1,0-1315.1,0
                c-118.2,0-259.1-19.7-293.8-33.8l-0.1,0h802.9c3.7,2.5,10,3.6,14.9,3.6H1894h86.1h109.1c5.3,0,9.4-1.2,12.5-3.6h814
                C2885.8,1001.3,2775.6,1023.9,2578.7,1023.9z"/>
        </g>
        <g id="caméra">
            <path id="o2" fill="none" stroke="#ff7300" d="M1952.9-52.1c0-5.5-4.5-10-10-10s-10,4.5-10,10s4.5,10,10,10S1952.9-46.6,1952.9-52.1z M1937-52.1
                c0-3.3,2.7-6,6-6s6,2.7,6,6c0,3.3-2.7,6-6,6S1937-48.8,1937-52.1z"/>
        </g>
        <g id="écran_1">
            <path id="o3" fill="none" stroke="#ff7300" d="M1144.6,976.7c-1.6-5.3-2.5-10.9-2.5-16.7V-42c0-32,26-58,58-58h1486c32,0,58,26,58,58V960
                c0,5.8-0.9,11.4-2.5,16.7h4.1c1.5-5.3,2.3-10.9,2.3-16.7V-42c0-34.2-27.8-62-62-62h-1486c-34.2,0-62,27.8-62,62V960
                c0,5.8,0.8,11.4,2.3,16.7H1144.6z"/>
        </g>
        <g id="écran_2">
            <path id="o4" fill="none" stroke="#ff7300" d="M2733.2,976.7h3.2c1.7-5.3,2.7-10.9,2.7-16.7V-42c0-29.2-23.8-53-53-53h-1486c-29.2,0-53,23.8-53,53V960
                c0,5.8,1,11.4,2.7,16.7h3.2c-1.9-5.2-2.9-10.8-2.9-16.7V-42c0-27.6,22.4-50,50-50h1486c27.6,0,50,22.4,50,50V960
                C2736.1,965.8,2735.1,971.5,2733.2,976.7z"/>
        </g>
        <g id="écran_3">
            <path id="o5" fill="none" stroke="#ff7300" d="M2727.1,961.2c0,10.6-6.5,15.5-12.5,15.5h-0.3v0h10.6c3.6-3.2,6.2-8.3,6.2-15.5v-24.1V-42
                c0-24.8-20.2-45-45-45h-1486c-24.8,0-45,20.2-45,45v979.1v24.1c0,7.2,2.7,12.3,6.3,15.5h10.1v0h-0.1c-4.7,0-12.3-4.2-12.3-15.5
                v-24.1V-42c0-22.6,18.4-41,41-41h1486c22.6,0,41,18.4,41,41v979.1V961.2z"/>
        </g>
        <g id="écran_4">
            <path id="o6" fill="none" stroke="#ff7300" d="M1219.2-20.6v900c0,2.8,2.2,5,5,5h1440c2.8,0,5-2.2,5-5v-900c0-2.8-2.2-5-5-5h-1440
                C1221.4-25.6,1219.2-23.4,1219.2-20.6z M2664.2,879.4h-1440v-900h1440V879.4z"/>
        </g>
        <g id="ronds_bas_ordi">
            <path id="o7" fill="none" stroke="#ff7300" d="M1625.5,1002.2c-2.1-1.4-7.7-1.9-11.9-1.9c-0.5,0-1,0-1.5,0c-8.2,0.2-8.8,2.1-9,2.9c-0.2,0.8-0.1,2,1.1,2.8
                c2.1,1.4,7.7,1.9,11.9,1.9c0.5,0,1,0,1.5,0c8.2-0.2,8.8-2.1,9-2.9C1626.9,1004.2,1626.7,1003.1,1625.5,1002.2z"/>
            <path id="o8" fill="none" stroke="#ff7300" d="M2264.1,1000.3c-0.5,0-1,0-1.5,0c-4.2,0-9.8,0.5-11.9,1.9c-1.2,0.8-1.4,2-1.1,2.8c0.3,0.9,0.8,2.7,9,2.9
                c0.5,0,1,0,1.5,0c0,0,0,0,0,0c4.2,0,9.8-0.5,11.9-1.9c1.2-0.8,1.4-2,1.1-2.8C2272.9,1002.4,2272.3,1000.6,2264.1,1000.3z"/>
        </g>
        <g id="lignes_graph">
                    <path id="o9" fill="none" stroke="#ff7300" d="M1406,625.6c17.6,0,35.2,0,52.8,0c45.9,0,91.9,0,137.8,0c64,0,128,0,191.9,0c71.7,0,143.4,0,215.1,0
                        c69.1,0,138.3,0,207.4,0c56.3,0,112.5,0,168.8,0c33.1,0,66.1,0,99.2,0c4.6,0,9.2,0,13.8,0c0.5,0,0.5-0.8,0-0.8
                        c-17.6,0-35.2,0-52.8,0c-45.9,0-91.9,0-137.8,0c-64,0-128,0-191.9,0c-71.7,0-143.4,0-215.1,0c-69.1,0-138.3,0-207.4,0
                        c-56.3,0-112.5,0-168.8,0c-33.1,0-66.1,0-99.2,0c-4.6,0-9.2,0-13.8,0C1405.6,624.8,1405.6,625.6,1406,625.6L1406,625.6z"/>
                    <path id="o10" fill="none" stroke="#ff7300" d="M1499,193.3c0,23.9,0,47.8,0,71.7c0,56.3,0,112.6,0,168.9c0,65.5,0,131,0,196.5c0,51.6,0,103.1,0,154.7
                        c0,15.6,0,31.2,0,46.8c0,0.5,0.8,0.5,0.8,0c0-23.9,0-47.8,0-71.7c0-56.3,0-112.6,0-168.9c0-65.5,0-131,0-196.5
                        c0-51.6,0-103.1,0-154.7c0-15.6,0-31.2,0-46.8C1499.7,192.8,1499,192.8,1499,193.3L1499,193.3z"/>
                    <path id="o11" fill="none" stroke="#ff7300" d="M1608.6,70.2c0,24.5,0,48.9,0,73.4c0,57.1,0,114.2,0,171.3c0,65.2,0,130.5,0,195.7c0,48.9,0,97.8,0,146.7
                        c0,11.4,0,22.9,0,34.3c0,0.5,0.8,0.5,0.8,0c0-24.5,0-48.9,0-73.4c0-57.1,0-114.2,0-171.3c0-65.2,0-130.5,0-195.7
                        c0-48.9,0-97.8,0-146.7c0-11.4,0-22.9,0-34.3C1609.4,69.7,1608.6,69.7,1608.6,70.2L1608.6,70.2z"/>
                    <path id="o12" fill="none" stroke="#ff7300" d="M1724,197.1c0,24,0,48.1,0,72.1c0,56.5,0,113,0,169.5c0,65.5,0,130.9,0,196.4c0,51,0,102,0,152.9
                        c0,14.6,0,29.2,0,43.8c0,0.5,0.8,0.5,0.8,0c0-24,0-48.1,0-72.1c0-56.5,0-113,0-169.5c0-65.5,0-130.9,0-196.4c0-51,0-102,0-152.9
                        c0-14.6,0-29.2,0-43.8C1724.8,196.7,1724,196.7,1724,197.1L1724,197.1z"/>
                    <path id="o13" fill="none" stroke="#ff7300" d="M1839.5,70.2c0,24,0,48.1,0,72.1c0,56.5,0,113,0,169.5c0,65.5,0,130.9,0,196.4c0,51,0,102,0,152.9
                        c0,14.6,0,29.2,0,43.8c0,0.5,0.8,0.5,0.8,0c0-24,0-48.1,0-72.1c0-56.5,0-113,0-169.5c0-65.5,0-130.9,0-196.4c0-51,0-102,0-152.9
                        c0-14.6,0-29.2,0-43.8C1840.2,69.7,1839.5,69.7,1839.5,70.2L1839.5,70.2z"/>
                    <path id="o14" fill="none" stroke="#ff7300" d="M1949.1,197.1c0,24,0,48.1,0,72.1c0,56.5,0,113,0,169.5c0,65.5,0,130.9,0,196.4c0,51,0,102,0,152.9
                        c0,14.6,0,29.2,0,43.8c0,0.5,0.8,0.5,0.8,0c0-24,0-48.1,0-72.1c0-56.5,0-113,0-169.5c0-65.5,0-130.9,0-196.4c0-51,0-102,0-152.9
                        c0-14.6,0-29.2,0-43.8C1949.9,196.7,1949.1,196.7,1949.1,197.1L1949.1,197.1z"/>
                    <path id="o15" fill="none" stroke="#ff7300" d="M2060.7,49c0,24,0,48.1,0,72.1c0,56.5,0,113,0,169.5c0,65.5,0,130.9,0,196.4c0,51,0,102,0,152.9
                        c0,14.6,0,29.2,0,43.8c0,0.5,0.8,0.5,0.8,0c0-24,0-48.1,0-72.1c0-56.5,0-113,0-169.5c0-65.5,0-130.9,0-196.4c0-51,0-102,0-152.9
                        c0-14.6,0-29.2,0-43.8C2061.5,48.5,2060.7,48.5,2060.7,49L2060.7,49z"/>
                    <path id="o16" fill="none" stroke="#ff7300" d="M2176.1,197.1c0,24,0,48.1,0,72.1c0,56.5,0,113,0,169.5c0,65.5,0,130.9,0,196.4c0,51,0,102,0,152.9
                        c0,14.6,0,29.2,0,43.8c0,0.5,0.8,0.5,0.8,0c0-24,0-48.1,0-72.1c0-56.5,0-113,0-169.5c0-65.5,0-130.9,0-196.4c0-51,0-102,0-152.9
                        c0-14.6,0-29.2,0-43.8C2176.9,196.7,2176.1,196.7,2176.1,197.1L2176.1,197.1z"/>
                    <path id="o17" fill="none" stroke="#ff7300" d="M2287.7,49c0,24,0,48.1,0,72.1c0,56.5,0,113,0,169.5c0,65.5,0,130.9,0,196.4c0,51,0,102,0,152.9
                        c0,14.6,0,29.2,0,43.8c0,0.5,0.8,0.5,0.8,0c0-24,0-48.1,0-72.1c0-56.5,0-113,0-169.5c0-65.5,0-130.9,0-196.4c0-51,0-102,0-152.9
                        c0-14.6,0-29.2,0-43.8C2288.5,48.5,2287.7,48.5,2287.7,49L2287.7,49z"/>
                    <path id="o18" fill="none" stroke="#ff7300" d="M2399.3,197.1c0,24,0,48.1,0,72.1c0,56.5,0,113,0,169.5c0,65.5,0,130.9,0,196.4c0,51,0,102,0,152.9
                        c0,14.6,0,29.2,0,43.8c0,0.5,0.8,0.5,0.8,0c0-24,0-48.1,0-72.1c0-56.5,0-113,0-169.5c0-65.5,0-130.9,0-196.4c0-51,0-102,0-152.9
                        c0-14.6,0-29.2,0-43.8C2400,196.7,2399.3,196.7,2399.3,197.1L2399.3,197.1z"/>
        </g>
        <g id="graph">
            <polyline fill="none" stroke="#ff7300" strokeWidth="4" strokeMiterlimit="10" points="1500.1,464.4 1610.4,565.4 1725.1,277.4 
                1842,492.6 1948.4,407.9 2060.9,598.1 2175.6,429 2289.8,404.6 2398.4,244.1 	"/>
        </g>
        <g id="points_graph">
            <circle fill="#ff7300" stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="1500.1" cy="464.4" r="7.6"/>
            <ellipse fill="none" stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="1948.4" cy="407.9" rx="7.6" ry="7.6"/>
            <ellipse fill="none" stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="2060.9" cy="598.1" rx="7.6" ry="7.6"/>
            <ellipse fill="none" stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="2175.6" cy="429" rx="7.6" ry="7.6"/>
            <circle fill="none" stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="2289.8" cy="404.6" r="7.6"/>
            <circle fill="none" stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="2398.4" cy="244.1" r="7.6"/>
            <circle fill="none" stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="1610.4" cy="565.4" r="7.6"/>
            <circle fill="none" stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="1725.1" cy="277.4" r="7.6"/>
            <circle fill="none"  stroke="#ff7300" strokeWidth="2" strokeMiterlimit="10" cx="1842" cy="492.6" r="7.6"/>
        </g>
        </svg>
    )
  }
  