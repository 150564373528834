

export default function PhoneSvg() {
  return (
    
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" id="phonesvg"
        viewBox="0 0 960 560" enableBackground="new 0 0 960 560" xmlSpace="preserve">
    <g id="contour">
        <path id="s1" fill="none" stroke="#ff7300" d="M563.3,505.9H395.8c-14.5,0-26.4-11.8-26.4-26.4V80.4c0-14.5,11.8-26.4,26.4-26.4h167.5
            c14.5,0,26.4,11.8,26.4,26.4v399.1C589.6,494.1,577.8,505.9,563.3,505.9z M395.8,57.1c-12.9,0-23.4,10.5-23.4,23.4v399.1
            c0,12.9,10.5,23.4,23.4,23.4h167.5c12.9,0,23.4-10.5,23.4-23.4V80.4c0-12.9-10.5-23.4-23.4-23.4H395.8z"/>
    </g>
    <g id="top_3">
        <path id="s2" fill="none" stroke="#ff7300" d="M448.8,81.1c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7
            C452.4,79.4,450.8,81.1,448.8,81.1z M448.8,74.5c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9
            C451.7,75.8,450.4,74.5,448.8,74.5z"/>
    </g>
    <g id="top_2">
        <path id="s3" fill="none" stroke="#ff7300" d="M479.5,66.6c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1
            C481.7,65.6,480.7,66.6,479.5,66.6z M479.5,63c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4
            C480.9,63.7,480.3,63,479.5,63z"/>
    </g>
    <g id="top_1">
        <path id="s4" fill="none" stroke="#ff7300" d="M493,79h-27c-1.1,0-1.9-0.8-1.9-1.9c0-1,0.9-1.9,1.9-1.9h27c1.1,0,1.9,0.8,1.9,1.9
            C494.9,78.2,494.1,79,493,79z M466,76c-0.6,0-1.2,0.5-1.2,1.1c0,0.6,0.5,1.1,1.2,1.1h27c0.6,0,1.2-0.5,1.2-1.1
            c0-0.6-0.5-1.1-1.2-1.1H466z"/>
    </g>
    <g id="fond_écran">
        <g opacity="0">
            <g>
                <defs>
                    <rect id="SVGID_1_" x="380.3" y="100.8" width="196" height="348.4"/>
                </defs>
                <use xlinkHref="#SVGID_1_"  overflow="visible" fill="#ff7300"/>
                <clipPath id="SVGID_2_">
                    <use xlinkHref="#SVGID_1_"  overflow="visible"/>
                </clipPath>
                <g clipPath="url(#SVGID_2_)">
                    <rect x="380.3" y="100.8" fill="#ff7300" width="196" height="348.4"/>
                </g>
            </g>
        </g>
    </g>
    <g id="écran">
        <path id="s5" fill="none" stroke="#ff7300" d="M576.4,449.2H380.3V100.9h196.1V449.2z M577.4,99.9h-1H380.3h-1v1v348.3v1h1h196.1h1v-1V100.9V99.9z"/>
    </g>
    <g id="btn_central_1">
        <path id="s6" fill="none" stroke="#ff7300" d="M479.5,495.2c-7.8,0-14.2-6.4-14.2-14.2c0-7.8,6.4-14.2,14.2-14.2c7.8,0,14.2,6.4,14.2,14.2
            C493.7,488.9,487.3,495.2,479.5,495.2z M479.5,467.6c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4c7.4,0,13.4-6,13.4-13.4
            C493,473.7,486.9,467.6,479.5,467.6z"/>
    </g>
    <g id="btn_central_2">
        <path id="s7" fill="none" stroke="#ff7300" d="M479.5,497.2c-8.9,0-16.2-7.2-16.2-16.2c0-8.9,7.2-16.2,16.2-16.2c8.9,0,16.2,7.2,16.2,16.2
            C495.7,490,488.4,497.2,479.5,497.2z M479.5,465.7c-8.5,0-15.4,6.9-15.4,15.4c0,8.5,6.9,15.4,15.4,15.4c8.5,0,15.4-6.9,15.4-15.4
            C494.9,472.6,488,465.7,479.5,465.7z"/>
    </g>
    <g id="btn_gauche">
            <path id="s8" fill="none" stroke="#ff7300" d="M371.8,165.6c0,1.1-0.9,2-2,2h0c-1.1,0-2-0.9-2-2v-24.9c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2V165.6z"/>
            <path id="s9" fill="none" stroke="#ff7300" d="M371.8,198.5c0,1.1-0.9,2-2,2h0c-1.1,0-2-0.9-2-2v-24.9c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2V198.5z"/>
            <path id="s10" fill="none" stroke="#ff7300" d="M371.4,121.6h-3.2c-0.2,0-0.4-0.2-0.4-0.5v-15.9c0-0.3,0.2-0.5,0.4-0.5h3.2c0.2,0,0.4,0.2,0.4,0.5v15.9
                C371.8,121.4,371.6,121.6,371.4,121.6"/>
    </g>
    <g id="btn_droite">
            <path id="s11" fill="none" stroke="#ff7300" d="M592.2,163c0,1.1-0.9,2-2,2h0c-1.1,0-2-0.9-2-2v-24.9c0-1.1,0.9-2,2-2h0c1.1,0,2,0.9,2,2V163z"/>
    </g>
    </svg>
  )
}
